import './style.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import { selectCampaigns } from '../../state/campaignSlice';
import { selectQueue } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import CollapsibleRow from './CollapsibleRow';

export default function CampaignsTable(props) {
  const { loading } = props;
  const campaigns = useSelector(selectCampaigns);
  const userType = useSelector(selectUserType);
  const queue = useSelector(selectQueue);

  return (
    <div className="patient-table">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="tag" />
              <TableCell align="center">Patient Name</TableCell>
              <TableCell align="center">Discharge Date</TableCell>
              <TableCell align="center">Campaign Start</TableCell>
              {queue === 'ReadyForCall' ? <TableCell align="center">First Contact</TableCell> : null}
              {queue === 'ReadyForCall' ? <TableCell align="center">Last Contact</TableCell> : null}
              <TableCell align="center">PCP</TableCell>
              {userType === USER_ROLES.ADMIN ? <TableCell align="center">Referral Status</TableCell> : null}
              {userType === USER_ROLES.ADMIN ? <TableCell align="center">Consent</TableCell> : null}
              {userType === USER_ROLES.ADMIN ? <TableCell align="center">Action</TableCell> : null}
              <TableCell align="center">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loader />
            ) : (
              campaigns.map((campaign, index) => (
                <CollapsibleRow key={campaign.id} index={index} campaign={campaign} campaigns={campaigns} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
