import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import DateTimeInput from '../../components/DateTimeInput/component';
import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';

export default function ContactAttempts({ campaignId }) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [attempts, setAttempts] = useState([]);

  useEffect(() => {
    getContactAttempts();
  }, []);

  const getContactAttempts = async () => {
    try {
      const token = await getAccessTokenSilently();
      const attempts = await HiroApi.getContactAttempts(campaignId, token);

      setAttempts(attempts);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addContactAttempt = async () => {
    try {
      const token = await getAccessTokenSilently();
      const newAttempt = await HiroApi.addContactAttempt(campaignId, token);

      setAttempts([...attempts, newAttempt]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteContactAttempt = async (attemptId, index) => {
    try {
      const token = await getAccessTokenSilently();
      const filtered = [...attempts.slice(0, index), ...attempts.slice(index + 1)];

      await HiroApi.deleteContactAttempt(campaignId, attemptId, token);
      setAttempts(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  const updateContactAttempt = async (attempt) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateContactAttempt(campaignId, attempt.id, attempt, token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactTypeChange = async (event, index) => {
    const type = event.target.value;
    const attemptsCopy = [...attempts];

    attemptsCopy[index].type = type;
    setAttempts(attemptsCopy);
    await updateContactAttempt(attempts[index]);
  };

  const handleContactEngagementChange = async (event, index) => {
    const engagement = event.target.value;
    const attemptsCopy = [...attempts];

    attemptsCopy[index].engagement = engagement;
    setAttempts(attemptsCopy);
    await updateContactAttempt(attempts[index]);
  };

  const handleContactTimeChange = (value, index) => {
    const attemptsCopy = [...attempts];

    attemptsCopy[index].timestamp = dayjs(value).utc().format();
    setAttempts(attemptsCopy);
    updateContactAttempt(attempts[index]);
  };

  const displayAttempts = () => {
    return attempts.map((attempt, index) => (
      <div key={attempt.id} className="attempt">
        <FormControl>
          <Select
            className="type"
            label="Type"
            value={attempt.type}
            onChange={(event) => handleContactTypeChange(event, index)}
          >
            <MenuItem value="Call">Call</MenuItem>
            <MenuItem value="SMS">SMS</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <Select
            className="type"
            label="Engagement"
            value={attempt.engagement}
            onChange={(event) => handleContactEngagementChange(event, index)}
          >
            <MenuItem value>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        <DateTimeInput label="Date/Time" value={attempt.timestamp} index={index} onChange={handleContactTimeChange} />
        <DeleteOutlineIcon className="add" onClick={() => deleteContactAttempt(attempt.id, index)} />
        <AddBoxOutlinedIcon className="add" onClick={addContactAttempt} />
      </div>
    ));
  };

  return (
    <div className="contact-attempts">
      <h3>Contact Attempts</h3>
      {loading ? <Loader /> : displayAttempts()}
    </div>
  );
}
