import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuditAction, setAction } from '../../state/auditHistorySlice';

export default function ActionFilter() {
  const dispatch = useDispatch();
  const selectedAction = useSelector(selectAuditAction);

  const handleChange = (event) => {
    const selectedAction = event.target.value;

    dispatch(setAction(selectedAction));
  };

  return (
    <FormControl size="small" className="action-filter">
      <InputLabel>Action</InputLabel>
      <Select value={selectedAction} onChange={handleChange} input={<OutlinedInput label="Action" />}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="Create">Create</MenuItem>
        <MenuItem value="Update">Update</MenuItem>
        <MenuItem value="Delete">Delete</MenuItem>
      </Select>
    </FormControl>
  );
}
