import './style.scss';

import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import {
  resetAuditHistoryFilters,
  selectAuditAction,
  selectAuditCategory,
  selectAuditEndDate,
  selectAuditStartDate,
  selectAuditUsers,
} from '../../state/auditHistorySlice';
import ActionFilter from './ActionFilter';
import CategoryFilter from './CategoryFilter';
import DateFilter from './DateFilter';
import UserFilter from './UserFilter';

export default function AuditHistoryFilters({ getAuditHistory, loading }) {
  const dispatch = useDispatch();
  const action = useSelector(selectAuditAction);
  const category = useSelector(selectAuditCategory);
  const endDate = useSelector(selectAuditEndDate);
  const selectedUsers = useSelector(selectAuditUsers);
  const startDate = useSelector(selectAuditStartDate);

  useEffect(() => {
    getAuditHistory();
  }, [action, category, endDate, selectedUsers, startDate]);

  const refresh = () => {
    getAuditHistory();
  };

  const resetFilters = () => {
    dispatch(resetAuditHistoryFilters());
  };

  return (
    <div className="filters">
      <UserFilter />
      <CategoryFilter />
      <ActionFilter />
      <DateFilter />

      <Button className="reset" onClick={resetFilters}>
        Reset
      </Button>

      {loading ? (
        <Loader size={35} />
      ) : (
        <IconButton onClick={refresh} className="refresh">
          <RefreshIcon />
        </IconButton>
      )}
    </div>
  );
}
