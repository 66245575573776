import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HiroApi from '../../HiroApi';
import { selectAuditUsers, setAuditUsers } from '../../state/auditHistorySlice';
import { selectAllUsers, setUsers } from '../../state/systemSlice';

export default function UserFilter() {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers); // []
  const selectedUsers = useSelector(selectAuditUsers); // []

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const token = await getAccessTokenSilently();
      const users = await HiroApi.getUsers(token);

      dispatch(setUsers(users));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const selectedUsers = event.target.value; // [userId, ...]

    dispatch(setAuditUsers(selectedUsers));
  };

  const selectAll = () => {
    const userIds = users.map((user) => user.id);

    dispatch(setAuditUsers(userIds));
  };

  const deselectAll = () => {
    dispatch(setAuditUsers([]));
  };

  const areAllUsersSelected = selectedUsers.length === users.length;

  const areNoUsersSelected = selectedUsers.length === 0;

  return (
    <FormControl size="small" className="user-filter">
      <InputLabel>Users</InputLabel>
      <Select multiple value={selectedUsers} onChange={handleChange} input={<OutlinedInput label="Users" />}>
        <MenuItem onClick={selectAll} disabled={areAllUsersSelected}>
          Select All
        </MenuItem>
        <MenuItem onClick={deselectAll} disabled={areNoUsersSelected}>
          Deselect All
        </MenuItem>
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.nickname}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
