import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuditCategory, setCategory } from '../../state/auditHistorySlice';

export default function CategoryFilter() {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectAuditCategory);

  const handleChange = (event) => {
    const selectedCategory = event.target.value;

    dispatch(setCategory(selectedCategory));
  };

  return (
    <FormControl size="small" className="category-filter">
      <InputLabel>Category</InputLabel>
      <Select value={selectedCategory} onChange={handleChange} input={<OutlinedInput label="Category" />}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="Appointment">Appointment</MenuItem>
        <MenuItem value="Campaign">Campaign</MenuItem>
        <MenuItem value="Discharge">Discharge</MenuItem>
        <MenuItem value="Ingestion">Ingestion</MenuItem>
        <MenuItem value="Patient">Patient</MenuItem>
      </Select>
    </FormControl>
  );
}
